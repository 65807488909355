import { Component } from "react";
import { connect } from "react-redux";
import { SButtom, SPage } from "servisofts-component";

class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  sendDiscordWebhook = async () => {
    const url = "https://discord.com/api/webhooks/1315752048175874180/BkWL1i1iWP0kASeDcuGMIIj_XmY6-TfxdseeWphBQClPx4MV9X6MPwMhJHzTcL6crhPT";

    const data = {
      content: "bienvenidos",
      tts: false,
      embeds: [],
      components: [],
      actions: {},
      username: "Familia cocacola",
      avatar_url: "https://yt3.ggpht.com/vSPJLpvbUC5Gpv1gCwYXlv6X5AkYYJezGL_CLCsi6WCtjAxgNm-1nAnyRvxjGVZrwr9aJU2Vkq8=s48-c-k-c0x00ffffff-no-rj",
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        alert("Webhook enviado correctamente.");
      } else {
        const errorData = await response.text();
        console.error("Error al enviar el webhook:", errorData);
        alert("Error al enviar el webhook. Verifica la URL y los datos.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Hubo un problema al enviar el webhook. Revisa tu conexión.");
    }
  };

  getAll() {
    var dase_datos = this.state.data;
    if (!dase_datos) return <SLoad type="skeleton" col={"xs-12"} height />;

    console.log("papi ", dase_datos)
    var usuarios = Model.usuario.Action.getAll();
    if (!usuarios) return <SLoad />;

    var aux = sucursal_usuario.Actions.getActive(this.props);
    if (!aux) return <SLoad />;

    // let asdasd = dase_datos.to

    return (
      <SText>{JSON.stringify(dase_datos)}</SText>
    )

    // return (
    //   <STable2
    //     limit={15}
    //     data={dase_datos}
    //     cellStyle={{
    //       fontSize: 12,
    //     }}
    //     header={[
    //       { key: "index", label: "#" },
    //       { key: "is_factura", label: "is_factura", center: true, width: 80 },
    //       { key: "codigo_factura", label: "codigo_factura", center: true, width: 80 },
    //       { key: "nit", label: "nit", center: true, width: 80 },
    //       { key: "razon_social", label: "razon_social", center: true, width: 150 },
    //     ]}

    //     filter={(item) => {
    //       // if (!item.fecha_factura_anulada) return false;
    //       // return sucursal_usuario.Actions.isActive(
    //       //   item.sucursal_key,
    //       //   this.props
    //       // );
    //     }}

    //   />
    // );
  }
  render() {
    return (
      <SPage title={"Alvarin"} disableScroll center>
        <SButtom type="danger" onPress={this.sendDiscordWebhook}>
          Cancelar
        </SButtom>
      </SPage>
    );
  }
}

const initStates = (state) => {
  return { state };
};
export default connect(initStates)(Test);
