import { Component } from 'react';
import { connect } from 'react-redux';
import { SDate, SHr, SImage, SLoad, SNavigation, SPage, SText, STheme, SView } from 'servisofts-component';
import SSocket from 'servisofts-socket';
import Entrenamiento from '../..';
import Model from '../../../../Model';
import Asistencia from '../../../Asistencia';
import Sucursal from '../../../Sucursal';
import RelojEntrenamiento from '../Lista/RelojEntrenamiento';
class Perfil extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.key_entrenamiento = SNavigation.getParam("key_entrenamiento");
    this.key_usuario = SNavigation.getParam("key_usuario");
  }

  componentDidMount() {
    Model.usuario.Action.getAll({ force: true });
  }
  gettitulo = () => {
    return <SText>{"Molinete"}</SText>;

  }

  getAsistenciaMolinete = (obj) => {
    var asistencia = Asistencia.Actions.getByKeyEntrenamiento({
      key_entrenamiento: this.key_entrenamiento,
    }, this.props);

    if (!asistencia) return <SLoad />

    // Ordenar asistencia por hora de registro (fecha_on)
    const asistenciaKeys = Object.keys(asistencia).sort((a, b) => {
      return new Date(asistencia[a].fecha_on) - new Date(asistencia[b].fecha_on);
    });

    const totalAutomatico = asistenciaKeys.filter(key => asistencia[key]?.descripcion === "automatico").length;

    // Renderizar cada tarjeta de usuario
    const renderUsuarioCard = (asistenciaData, asistenciaKey) => {
      const usuario = Model.usuario.Action.getByKey(asistenciaData.key_usuario);
      if (!usuario) return <SLoad />;
      if (!asistenciaData.descripcion) return null;

      const isCurrentUser = asistenciaData.key_usuario === this.key_usuario;

      return (
        <SView key={asistenciaKey} width={120} height={120} colSquare style={{ padding: 4 }}
          onPress={() => SNavigation.navigate("ClientePerfilPage", { key: asistenciaData.key_usuario })}
        >
          <SView
            card
            border={isCurrentUser ? "cyan" : STheme.color.card}
            height col={"xs-12"} center style={{ overflow: "hidden" }}
          >
            <SText capitalize center fontSize={12}>Molinete</SText>
            <SHr />
            <SView width={40} height={40}> <SImage src={`${SSocket.api.root}usuario/${usuario.key}`} /> </SView>
            <SHr />
            <SText center fontSize={12} height={24}>{`${usuario.Nombres} ${usuario.Apellidos}`}</SText>
            <SText center fontSize={10} color={"green"}>Hr. Reg: {new SDate(asistenciaData.fecha_on).toString('hh:mm')}</SText>
          </SView>
        </SView>
      );
    };
    return (
      <SView col={"xs-12"} center>
        <SView col={"xs-12"} center row>

          {totalAutomatico === 0 ? (
            <SText center fontSize={14}>No hay asistencias manuales</SText>
          ) : (
            <>
              <SText center bold fontSize={14}>ASISTENCIA MOLINETE (Total: {totalAutomatico})</SText>
              <SHr />
              {asistenciaKeys.map(asistenciaKey =>
                renderUsuarioCard(asistencia[asistenciaKey], asistenciaKey)
              )}
            </>
          )}
        </SView>
      </SView>
    );
  }


  getAsistencia_manual = (obj) => {
    // Obtener las asistencias filtradas por "manual"
    const asistencia = Asistencia.Actions.getByKeyEntrenamiento({
      key_entrenamiento: this.key_entrenamiento,
    }, this.props);

    if (!asistencia) return <SLoad />;

    // Filtrar las asistencias con descripción "manual" y ordenarlas por fecha_on
    const asistenciaManual = Object.keys(asistencia)
      .filter(key => asistencia[key]?.descripcion !== "automatico")
      .sort((a, b) => new Date(asistencia[a].fecha_on) - new Date(asistencia[b].fecha_on)); // Ordenar por fecha_on

    // Función para renderizar cada tarjeta de usuario
    const renderUsuarioCard = (key) => {
      const usuario = Model.usuario.Action.getByKey(asistencia[key].key_usuario);
      if (!usuario) return <SLoad />;

      return (
        <SView
          key={key}
          width={120}
          height={120}
          colSquare
          style={{ padding: 4 }}
          onPress={() => SNavigation.navigate("ClientePerfilPage", { key: asistencia[key].key_usuario })}
        >
          <SView
            card
            border={asistencia[key].key_usuario === this.key_usuario ? "cyan" : STheme.color.card}
            height
            col={"xs-12"}
            center
            style={{ overflow: "hidden" }}
          >
            <SText capitalize center fontSize={12}>Manual</SText>
            <SHr />
            <SView width={40} height={40}>
              <SImage src={`${SSocket.api.root}usuario/${usuario.key}`} />
            </SView>
            <SHr />
            <SText capitalize center fontSize={12}>
              {`${usuario.Nombres} ${usuario.Apellidos}`}
            </SText>
            <SText center fontSize={10} color={"cyan"}>
              Hr. Reg: {new SDate(asistencia[key].fecha_on).toString('hh:mm')}
            </SText>
          </SView>
        </SView>
      );
    };

    // Mostrar el total de asistencias manuales
    const totalManuales = asistenciaManual.length;

    return (
      <SView col={"xs-12"} center>
        <SView col={"xs-12"} center row>
          {totalManuales === 0 ? (
            <SText center fontSize={14}>No hay asistencias manuales</SText>
          ) : (
            <>
              <SText center bold fontSize={14}>ASISTENCIA MANUAL (Total: {totalManuales})</SText>
              <SHr />
              {asistenciaManual.map((key) => renderUsuarioCard(key))}
            </>
          )}
        </SView>
      </SView>
    );
  };


  getEntrenamiento() {
    var entrenamiento = Entrenamiento.Actions.getByKey(this.key_entrenamiento, this.props);
    if (!entrenamiento) return <SLoad />
    return <SView col={"xs-11"} center>
      {this.getSucursal(entrenamiento.key_sucursal)}
      {this.getUsuario(entrenamiento.key_usuario)}
      <SHr height={32} />
      {this.getEstado(entrenamiento)}
    </SView>
  }
  getSucursal = (key) => {
    var sucursal = Sucursal.Actions.getByKey(key, this.props);
    if (!sucursal) return <SLoad />
    return <SView col={"xs-11"} border={"red"} row style={{
      borderRadius: "18",
      // overflow: "hidden",
    }}>
      <SView width={40} height={40} style={{
        borderRadius: "50%",
        overflow: "hidden",
      }} >
        <SImage src={SSocket.api.root + "sucursal/" + key} />
      </SView>
      <SView flex style={{
        justifyContent: "center",
      }}>
        <SText>{`${sucursal.descripcion}`}</SText>
        <SText fontSize={10} color={"#999"}>{`${!sucursal.direccion ? "" : sucursal.direccion}`}</SText>
      </SView>
    </SView>
  }
  getUsuario = (key) => {
    if (!key) return <SText fontSize={18} capitalize>{`Molinete`}</SText>

    var usuario = Model.usuario.Action.getByKey(key);
    if (!usuario) return <SLoad />
    return <SView col={"xs-12"} center >
      {/* <SText fontSize={10} color={"#999"} >{`Entrenador`}</SText> */}

      <SView width={70} height={70} backgroundColor={"#66000022"} style={{
        borderRadius: "50%",
      }}>
        <SImage src={SSocket.api.root + "usuario/" + key} />
      </SView>
      <SHr height={8} />
      <SText fontSize={18} capitalize>{`${usuario.Nombres} ${usuario.Apellidos}`}</SText>
    </SView>
  }
  getEstado = (obj) => {
    if (new SDate(obj.fecha_fin).isBefore(new SDate())) {
      return <SView col={"xs-12"} height={60} center row>


        <SView width={100} height={50} backgroundColor={"#600"} style={{
          borderRadius: 8,
        }} center>
          <SText fontSize={16} bold>{new SDate(obj.fecha_inicio).toString("hh:mm")}</SText>
        </SView>
        <SText color={"#999"} fontSize={12}>{"Hora de Iniciación."}</SText>


        <SView width={100} height={50} backgroundColor={"#600"} style={{
          borderRadius: 8,
        }} center>
          <SText fontSize={16} bold>{new SDate(obj.fecha_fin).toString("hh:mm")}</SText>
        </SView>
        <SText color={"#999"} fontSize={12}>{"Hora de finalizacion."}</SText>


      </SView>
    }
    return <SView col={"xs-12"} height={60} center>
      <RelojEntrenamiento data={obj} />
    </SView>

  }
  render() {
    return (
      <SPage title={'Perfil '}>
        <SView col={"xs-12"} center>
          {this.getEntrenamiento()}
          <SView col={"xs-10"} center>
            <SHr height={40} />
            {this.getAsistenciaMolinete({ key: this.key_entrenamiento })}
            <SHr height={40} />
            {this.getAsistencia_manual({ key: this.key_entrenamiento })}
          </SView>
        </SView>
      </SPage>
    );
  }
}
const initStates = (state) => {
  return { state }
};
export default connect(initStates)(Perfil);